var royalties = function() {
	var royalties = this;

	this.calcolaTotale = function() {
		var tot = 0.00;

		$('[data-interaction=percentuale]').each(function() {
			tot += parseFloat($(this).val());
		});

		$('[data-interaction=totale]').html(parseFloat(tot).toFixed(2));

		if(tot == parseFloat("100.00").toFixed(2)) {
			$('[data-interaction=save]').removeAttr('disabled');
		} else {
			$('[data-interaction=save]').attr('disabled', 'disabled');
		}
	};

	this.bindEvents = function() {
		$("[data-interaction=save]").unbind("click").bind("click", function(e) {
			e.preventDefault();

			var idForm = $(this).attr('data-form-id');

			app.formSubmit($("#" + idForm).attr("action"), $("#" + idForm), {}, function(data) {
				if(data.response) {
					app.success("", data.message);
					app.href(data.redirectUrl);
				} else {
					app.error("", data.message);
				}
			});

		});

		$("[data-interaction=btnSubmitForm]").unbind("click").bind("click", function(e) {
			e.preventDefault();

			var idForm = $(this).attr('data-form-id');

			app.formSubmit($("#" + idForm).attr("action"), $("#" + idForm),{}, function(data) {
				if(data.response) {
					app.href(data.message);
				} else {
					app.error("", data.message);
				}
			});

		});

		$('[data-interaction=percentuale]').unbind('blur').bind('blur', function() {
			var old = parseFloat($(this).attr('data-old'));
			var val = parseFloat($(this).val().replace(",", "."));

			// mi assicuro di inserire un numero valido
			if(!isNaN(val)) {
				$(this).val(val.toFixed(2));
				$(this).attr('data-old', $(this).val());
			}
			else if(!isNaN(old)) $(this).val(old.toFixed(2));
			else $(this).val("0.00");

			royalties.calcolaTotale();
		});
	};

	this.bindEvents();
	this.calcolaTotale();

};