var formatter = {

    bool: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data ? '<i style="color: green;" class="fa fa-circle"></i>' : '<i style="color: red;" class="fa fa-circle"></i>';
    },

    checkbox: function (cell_data) {
        return (cell_data ? '<i style="" class="fa fa-check-square-o"></i>' : '<i style="" class="fa fa-square-o"></i>');
    },

    user_enabled: function (table, td, cell_data, row_data, row_index, col_index) {
        var ret = "";

        if (cell_data) {
            ret = '<button data-interaction="enable" data-disable="1" class="btn btn-xs btn-success"><i class="fa fa-check"></i></button>'
        } else {
            ret = '<button data-interaction="enable" class="btn btn-xs btn-danger"><i class="fa fa-times"></i></button>'
        }

        return ret;
    },

    order_evaso: function (table, td, cell_data, row_data, row_index, col_index) {
        var ret = '';

        if (parseInt(cell_data) == 60) {
            ret = '<button class="btn btn-xs btn-warning" data-interaction="evaso" data-url="/order/evaso/' + row_data[0] + '"><i class="fa fa-paper-plane"></i> Evaso</button>';
        }

        return ret;
    },

    btn_archivia_feedback: function (table, td, cell_data, row_data, row_index, col_index) {
        return '<button class="btn btn-xs btn-warning" data-interaction="archivia_feedback" data-url="' + app.baseUrl + '/feedback/archive/' + row_data[0] + '"><i class="fa fa-archive"></i></button>';
    },

    /**
     * Formatter utilizzato per far comparire il bottone di acquisto.
     */
    btn_aggiungi_al_carrello: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != '' && parseInt(cell_data) > 0) {
            return '<button class="btn btn-xs btn-warning" data-interaction="aggiungi_al_carrello" data-articleid="' +cell_data+ '"><i class="fa fa-shopping-cart"></i></button>';
        }
        return " ";
    },

    btn_edit_kit_info: function (table, td, cell_data, row_data, row_index, col_index) {
        return '<button class="btn btn-xs btn-info" data-interaction="assegna_kit" data-orderdetailid="' + cell_data + '">' + labalModificaKit + '</button>';
    },

    dashboard_set_codice_soggetto: function (table, td, cell_data, row_data, row_index, col_index) {
        return '<button class="btn btn-xs btn-info" data-interaction="dashboard_set_codice_soggetto" data-soggettoid="' + cell_data + '"><i class= "fa fa-pencil"></i> ' + labelModificaCodice + '</button>';
    },

    btn_show_media: function (table, td, cell_data, row_data, row_index, col_index) {
        // debugger;
        if (cell_data != '' && parseInt(cell_data) > 0) {
            return '<a href="download/doc/' + cell_data + '"><i class="fa fa-file-pdf-o"></i></a>';
        }
        return " ";
    },
    small: function (table, td, cell_data, row_data, row_index, col_index) {
        return "<small>" + cell_data + "</small>";
    },
    valuta_euro : function (table, td, cell_data, row_data, row_index, col_index) {
        if(cell_data!='')
            return parseFloat(cell_data).toFixed(2) + "€";
        else
            return '';
    },

    percentuale : function (table, td, cell_data, row_data, row_index, col_index) {
        if(cell_data!='')
            return parseFloat(cell_data).toFixed(2) + "%";
        else
            return '';
    }


};