var feedbackUser = function() {

	this.bindEvents = function() {
		$("#btnFeedbackSend").unbind("click").bind("click", function(e){
			e.preventDefault();

			$("#btnFeedbackSend").attr("disabled", "disabled");
			app.formSubmit($("#frmFeedbackSend").attr("action"), $("#frmFeedbackSend"),{}, function(data){
				if(data.response){
					app.success(data.message);
					setTimeout(function(){
						app.href('dashboard');
					},1000);
				}else{
					app.error("", data.message);
					$("#btnFeedbackSend").removeAttr("disabled");
				}
			},function(){
				$("#btnFeedbackSend").removeAttr("disabled");
			});

		});
	};

	this.bindEvents();
};
