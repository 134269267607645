var help = function(idForm) {

	this.bindEvents = function() {
		$(idForm).find('[type=submit]').unbind("click").bind("click", function(e) {
			e.preventDefault();

			app.formSubmit($(idForm).attr("action"), $(idForm),{}, function(data) {
				if(data.response) {
					location.href="#help";
				} else {
					app.error("", data.message);
				}
			});

		});

		$(idForm + " #type").unbind('change').bind('change', function() {
			$("[data-interaction=target]").hide();
			if($(this).val() == 30) $("#file").attr("disabled", "disabled");
			$("#" + $(this).val()).show();
		});


	};

	this.bindEvents();

};

help.initDropzone = function() {
	this.on('sending', function() {
		$("#dropzone_error").hide();
		$("#dropzone_success").hide();
		app.block(1);
	});
};

help.successDropzone = function(dropzone, data) {
	if(data.response) {
		$("#dropzone_success").show();
	} else {
		$("#dropzone_error").show();
	}
};

help.errorDropzone = function() {
	$("#dropzone_error").show();
};