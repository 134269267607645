var translation = {
    init: function () {
        translation.bindEvents();
    },
    bindEvents: function(){
        $('[data-interaction=buildPos]').unbind('click').bind('click', function(){
            $('[data-update-processing]').removeClass('hidden');
            $('[data-update-processing] .processingOutout').html($('[data-update-processing] .processingOutout').attr('data-loading-message'));

            $.post('translation/updatePos', {}, function(data){
                $('[data-update-processing] .processingOutput').html(data.message);
            });
        });

        $('[data-interaction=commitPos]').unbind('click').bind('click', function(){
            $('[data-update-processing]').removeClass('hidden');
            $('[data-update-processing] .processingOutout').html($('[data-update-processing] .processingOutout').attr('data-loading-message'));

            $.post('translation/commitPos', {}, function(data){
                $('[data-update-processing] .processingOutput').html(data.message);
            });
        });

    },
    uploadPo: function(tmp, data){
        if(!data.response){
            $("[data-interaction=uploadFailed]").show();
            $("[data-interaction=uploadFailed] [data-interaction=errorMsg]").html(data.message);
        }else{
            $("[data-interaction=uploadSuccess]").show();
        }
    }
};
