var feedbackAdmin = function() {

	var feedbackAdmin = this;

	this.afterDraw=function(){
		$("[data-interaction=archivia_feedback]").unbind("click").bind("click", function(){
			$.post($(this).attr("data-url"), {}, function(data){
				if(data.response){
					feedbackDT.table.draw("page");
				}else{
					app.error(data.message);
				}
			});
		});
	};

};
