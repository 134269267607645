var doc = function() {

	this.bindEvents = function() {

		$("#filterProduct").unbind("change").bind("change", function() {
			var url = $(this).data("url");
			url = url + "?productSelected=" + $(this).val();

			app.href(url);
		});

		$("#title").keyup(function() {
			$("#article_description").val($(this).val());
		});

		$("#code").keyup(function() {
			$("#article_code").val($(this).val());
		});

		$("#btnDocument").unbind("click").bind("click", function (e) {
			e.preventDefault();

			var elemId = $("#frmDocument").attr("data-id");
			var form = $("#frmDocument");

			app.formSubmit($("#frmDocument").attr("action"), $("#frmDocument"), {}, function (data) {
				if (data.response) {
					app.success(data.message);
					
					if($.trim(elemId)!="" && parseInt(elemId)){
						app.href(form.attr('action'));
					}else{
						app.href(form.attr('action') + "/create");
					}
				} else {
					app.error("", data.message);
				}
			});

		});

		var art = new article();
		art.bindCalcoloSconto();
		art.calcolaPrezzoScontato();
	};

	this.bindEvents();

};