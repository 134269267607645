var article = function () {

    var article = this;

    this.bindCalcoloSconto = function() {
        $("[data-article-sconto=tipo], [data-article-sconto=valore], [data-article-sconto=importo]").unbind("change").bind("change", function(){
            article.calcolaPrezzoScontato();
        });
    };

    this.calcolaPrezzoScontato = function() {
        var TIPO_FISSO = 0;
        var TIPO_PERCENTUALE = 10;

        var $tipo = $("[data-article-sconto=tipo]").val();
        var $valore = $("[data-article-sconto=valore]").val();
        var $importo = $("[data-article-sconto=importo]").val();
        var $risultatoObj = $("[data-article-sconto=risultato]");

        var $sconto = 0;
        switch (parseInt($tipo)){
            case TIPO_FISSO:
                $sconto = $valore;

                $risultatoObj.val(($importo - $sconto).toFixed(2));
                break;
            case TIPO_PERCENTUALE:
                $sconto = ($importo * $valore / 100).toFixed(2);

                $risultatoObj.val(($importo - $sconto).toFixed(2));
                break;
        }
    };

};