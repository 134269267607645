var cart_details = function() {
	
	this.bindEvents = function() {

		$('[data-interaction="cart_change_quantity_p1"]').unbind('click').bind('click', function() {
			$.post("cart_details/changeQuantity", {cartId:$(this).parents("tr").attr("data-cartid"), value:+1}, function(){
				app.reload();
			})
		}),

		$('[data-interaction="cart_change_quantity_m1"]').unbind('click').bind('click', function() {
			$.post("cart_details/changeQuantity", {cartId:$(this).parents("tr").attr("data-cartid"), value:-1}, function(){
				app.reload();
			})
		}),


		$('[_data-interaction=delete]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');
			
			app.block(1);
			$.delete(url)
				.success(function(data) {

					if(data.response) {
						console.log("a");
						app.reload();
					} else {
						app.warning("", data.message);
					}
					app.block(0);
				})
				.error(function() {
					app.block(0);
					app.error("", "Error delete article");
				});
		});
	};
	this.updateTotal = function() {
		$.get(app.baseUrl + '/cart_count', function(data){
			if (data.response)
				$('[data-interaction=cartArticleCount]').html(data.message);
		});
	};
	this.bindBuyButton = function() {
		var cart_object = this;

		$("[data-interaction=aggiungi_al_carrello]").unbind('click').bind('click', function () {
			$.post(app.baseUrl + "/cart_details", {idArticle: $(this).attr('data-articleid')}, function(data){
				if(data.response){
					if($.trim(data.message)!="")
						app.success(data.message);

					if(data.ask_for_subscription) {
						// richiede sottoscrizione
						$("[data-interaction='subscription_necessaria']").show();
					}else {

						cart_object.updateTotal();

						if (data.show_cart_button) {
							$("[data-interaction=goToChart]").show();
							if ($("[data-interactgion=cartMsgContainer]").length) {
								$("[data-interacttion=cartMsgContainer]").hide();
							}
						} else {
							if ($("[data-interacttion=cartMsgContainer]").length) {
								$("[data-interacttion=cartMsgContainer]").show();
								$("[data-interacttion=cartMsgContainer]").html(data.cartMsg);
							}
						}
					}
				}else{
					app.error(data.message);
				}
			})
		});

		$("[data-interaction=goToChart]").unbind('click').bind('click', function () {
			app.href(app.baseUrl + "/cart_details");
		});
	};

	this.bindEvents();
	
};
