var order = function(idForm, dataTable, confirmMessage) {

	this.bindEvents = function() {
		$("#btnConfirmOrder").unbind("click").bind("click", function(e) {
			e.preventDefault();

			app.formSubmit($(idForm).attr("action"), $(idForm),{}, function(data) {
				if(data.response) {
					app.href(data.message);
				} else {
					app.error("", data.message);
				}
			});

		});

		$('[data-interaction=evaso]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');

			if(!confirm(confirmMessage)) return;
			
			app.block(1);
			$.post(app.baseUrl + url)
				.success(function(data) {
					if(data.response) {
						app.success("", data.message);
						dataTable.table.draw('page');		
					} else {
						app.warning("", data.message);
					}
					app.block(0);
				})
				.error(function() {
					app.block(0);
					app.error("", "Error!");
				});
		});

		$('[data-interaction=assegna_kit]').unbind('click').bind('click', function() {
			var objBtn = $(this);
			var idOrderDetail = $(this).attr("data-orderdetailid");
			$.post(app.baseUrl+"/order/kitinfo/" + idOrderDetail, {}, function(data){
				$('#modalInfoKit .modal-body').html(data);
				$("#modalInfoKit").modal('show');
			});
			$('#modalInfoKit .btn-primary').unbind('click').bind('click', function() {

				app.formSubmit(app.baseUrl+"/order/kitinfo/" + idOrderDetail + "/save",$('#frmKitInfo'), {}, function(data){
					if(data.response) {
						$('#modalInfoKit .close').click();
						// setTimeout(function(){
						// 	objBtn.click();
						// },500);
						dataTable.table.draw('page');
					}else{
						app.error("", data.message);
					}
				});
			});
		});

	};

	this.bindEvents();

};