var crud = function(options) {

	this.bindEvents = function() {
		$('[data-interaction=delete]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');
			var error = $(this).attr('data-error');
			var reload = $(this).attr('data-reload');
			var href = $(this).attr('data-href');

			app.block(1);
			$.delete(url)
				.success(function(data) {
					if(data.response) {
						new cart_details().updateTotal()

						if(reload) app.reload();
						if(href) app.href(href);
					} else {
						app.warning("", data.message);
					}
					app.block(0);
				})
				.error(function() {
					app.block(0);
					app.error('', 'Delete error!');
				});
		});

		$('[data-interaction=save]').unbind('click').bind('click', function(e) {
			e.preventDefault();

			var error = $(this).attr('data-error');
			var reload = $(this).attr('data-reload');
			var href = $(this).attr('data-href');
			var fastsavebutton=$(this).attr('data-fastsavebutton');
			var aftersaveurl=$(this).attr('data-aftersaveurl') || null;
			var forceSaveUrl=$(this).attr('data-forcesaveurl') || $("#" + options.form).attr("action");

			var form = $("#" + options.form);
			var elemId = $("#" + options.form).attr("data-id");
			
			app.block(1);
			app.formSubmit(forceSaveUrl, $("#" + options.form), {}, function(data) {
				if(data.response) {
					app.success("", "Record salvato.");
					if(fastsavebutton){
						if($.trim(elemId)!="" && parseInt(elemId)){
							if (aftersaveurl != null)
								app.href(aftersaveurl);
							else
								app.href(form.attr('action'));
						}else{
							app.href(form.attr('action') + "/create");
						}
					}else {
						if (reload) app.reload();
						if (href) app.href(href);
					}
				} else {
					app.warning("", data.message);
				}
				app.block(0);
			}, function() {
				app.error("", error);
				app.block(0);
			});
		});
	};

	this.bindEvents();

};