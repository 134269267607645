var manager = function () {

    var manager = this;

    this.bindEvents_variable = function () {
        $("#btnConfigurationVariable").unbind("click").bind("click", function (e) {
            e.preventDefault();

            app.formSubmit($("#frmConfigurationVariable").attr("action"), $("#frmConfigurationVariable"), {}, function (data) {
                if (data.response) {
                    app.success(data.message);
                    app.reload();
                } else {
                    app.error("", data.message);
                }
            });

        });
    };

    this.bindEvents_licenzeShow = function () {
        $("#btnLicenza").unbind("click").bind("click", function (e) {
            e.preventDefault();

            var elemId = $("#frmLicenza").attr("data-id");
            var form = $("#frmLicenza");

            app.formSubmit($("#frmLicenza").attr("action"), $("#frmLicenza"), {}, function (data) {
                if (data.response) {
                    app.success(data.message);

                    if($.trim(elemId)!="" && parseInt(elemId)){
                        app.href(form.attr('action'));
                    }else{
                        app.href(form.attr('action') + "/create");
                    }
                } else {
                    app.error("", data.message);
                }
            });

        });

        var art = new article();
        art.bindCalcoloSconto();
        art.calcolaPrezzoScontato();

    };

    this.bindEvents_productFamilyShow = function () {
        $("#btnSaveProductFamily").unbind("click").bind("click", function (e) {
            e.preventDefault();

            var elemId = $("#frmSubscription").attr("data-id");
            var form = $("#frmSubscription");

            app.formSubmit($("#frmSubscription").attr("action"), $("#frmSubscription"), {}, function (data) {
                if (data.response) {
                    app.success(data.message);

                    if($.trim(elemId)!="" && parseInt(elemId)){
                        app.href(form.attr('action'));
                    }else{
                        app.href(form.attr('action') + "/create");
                    }

                } else {
                    app.error("", data.message);
                }
            });

        });
    };
    this.bindEvents_productShow = function () {
        $("#btnSaveProduct").unbind("click").bind("click", function (e) {
            e.preventDefault();

            var elemId = $("#frmProduct").attr("data-id");
            var form = $("#frmProduct");

            app.formSubmit($("#frmProduct").attr("action"), $("#frmProduct"), {}, function (data) {
                if (data.response) {
                    app.success(data.message);

                    if($.trim(elemId)!="" && parseInt(elemId)){
                        app.href(form.attr('action'));
                    }else{
                        app.href(form.attr('action') + "/create");
                    }
                } else {
                    app.error("", data.message);
                }
            });

        });
    };

    this.bindEvents_subscriptionShow = function () {
        $("#btnSubscription").unbind("click").bind("click", function (e) {
            e.preventDefault();

            var elemId = $("#frmSubscription").attr("data-id");
            var form = $("#frmSubscription");

            app.formSubmit($("#frmSubscription").attr("action"), $("#frmSubscription"), {}, function (data) {
                if (data.response) {
                    app.success(data.message);

                    if($.trim(elemId)!="" && parseInt(elemId)){
                        app.href(form.attr('action'));
                    }else{
                        app.href(form.attr('action') + "/create");
                    }
                } else {
                    app.error("", data.message);
                }
            });

        });

        $("#article_description").keyup(function() {
            $("#articolo_descrizione").val($(this).val());
        });

        var art = new article();
        art.bindCalcoloSconto();
        art.calcolaPrezzoScontato();
        
    };
};