var register = function (geonameUrl) {

    this.bindEvents = function () {
        // verifica partita IVA
        $('#registerForm').find('#customer_type').unbind('change').bind('change', function () {
            $('[data-company]').hide();
            $('[data-company]').attr('disabled', 'disabled');
            switch (parseInt($(this).val())) {
                case 10:
                    $('[data-company-azienda="1"]').show();
                    $('[data-company-azienda="1"]').removeAttr('disabled');
                    break;
                case 20:
                    $('[data-company-privato="1"]').show();
                    $('[data-company-privato="1"]').removeAttr('disabled');
                    break;
            }

        });

        // gestione cookie lingua
        $("#id_language").unbind('change').bind('change', function () {
            Cookies.set("id_language", $("#id_language").val(), { path: '' });

            location.href=location.href;
        });
        if(Cookies.get("id_language")){
            $("#id_language").val(Cookies.get("id_language"));
        }


        $('#registerForm').find('#vat').unbind('change').bind('change', function () {
            var vat = $(this).val().trim();
            var self = this;

            $('#spinner').hide();
            $('#ok').hide();
            $('#ko').hide();

            $(self).css('background-color', 'transparent');
            $('#spinner').show();
            $.post(app.baseUrl + '/register/checkVat', {vat: vat})
                .success(function (data) {
                    if (data.response) {
                        if (data.message.name) $('#company_name').val(data.message.name);
                        $(self).css('background-color', 'lightgreen');
                        $('#ok').show();
                    } else {
                        $(self).css('background-color', 'lightcoral');

                        $('#ko').show();
                    }
                    $('#spinner').hide();
                })
                .error(function () {
                    // TODO
                    $(self).css('background-color', 'lightcoral');
                    $('#ko').show();
                    $('#spinner').hide();
                });
        });

        $('#registerForm').find('#customer_type').trigger('change');

        $('#country').unbind('change').bind('change', function () {
            var currency = $(this).find('option:selected').attr('data-currency');
            $('#currency').val(currency);
        });

        $('#country').trigger("change");

    };

    this.bindEvents();
    new geonames(geonameUrl);
};
