var WECookieLaw = {

    init: function(config){

        config = config || {};

        WECookieLaw.blocking = config.blocking || false;
        WECookieLaw.disableAcceptOnScrolling = config.disableAcceptOnScrolling || false;

        WECookieLaw.path = config.path || "mvc/public/assets/js/wecookielaw/";
        WECookieLaw.cancelLink = config.cancelLink ||  'http://ec.europa.eu/justice/data-protection/';
        WECookieLaw.acceptButton = config.acceptButton || "Accetto";
        WECookieLaw.cancelButton = config.cancelButton || "Esci dal sito"

        function run(){

            var language = window.navigator.userLanguage || window.navigator.language;
            switch(language){
                case "it_IT": case "it-IT":
                linkPolicy = "https://www.iubenda.com/privacy-policy/7890952/full-legal";
                message = "<b>Informativa</b><br>" +
                    "Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di <b>cookie</b> necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie, consulta la <a target='_blank' href='" + linkPolicy + "'>cookie policy</a>.<br>" +
                    "Accettando l'informativa, scorrendo questa pagina o proseguendo la navigazione in altra maniera, acconsenti all'uso dei cookie.";
                acceptButton = "Accetto";
                cancelButton = "Esci dal sito";
                break;
                case "fr_FR": case "fr-FR":
                linkPolicy = "https://www.iubenda.com/privacy-policy/7890950/full-legal";
                message = "<b>Divulgation</b><br>" +
                    "En utilisant argoservice, vous acceptez que nous <a target='_blank' href='" + linkPolicy + "'>appliquions des cookies</a> afin de vous offrir une expérience optimale.";
                acceptButton = "Acceptable";
                cancelButton = "Déconnexion";
                break;

                case "es_ES": case "es-ES":
                linkPolicy = "https://www.iubenda.com/privacy-policy/7890953/full-legal";
                message = "<b>Divulgación</b><br>" +
                    "Este sitio web o de terceros herramientas utilizadas por ello utilizan cookies necesarias para la operación y útiles para los fines descritos en la política de cookies. Para obtener más información o optar por todas o algunas galletas, consulte la <a target='_blank' href='" + linkPolicy + "'>política de cookies</a>."+
                    "Mediante la aceptación de la información, vaya esta página o continuar con la navegación de cualquier otra manera, usted autoriza la utilización de cookies.";
                acceptButton = "Aceptable";
                cancelButton = "Salir";
                break;
                case "de_DE": case "de-DE":
                linkPolicy = "https://www.iubenda.com/privacy-policy/7890951/full-legal";
                message = "<b>Disclosure</b><br>" +
                    "Dieser Website oder Tools von Drittanbietern von diesem benutzten sie Cookies für den Betrieb notwendig und sinnvoll verwenden, um die in der Cookie-Richtlinie genannten Zwecke. Um mehr zu erfahren oder sich aus der alle oder einzelne Cookies finden Sie in der <a target='_blank' href='" + linkPolicy + "'>Cookie-Politik</a>."+
                    "Durch die Annahme der Informationen, blättern Sie diese Seite oder Weiterbildung Navigation auf andere Weise, die Sie die Verwendung von Cookies einverstanden.";
                acceptButton = "Login";
                cancelButton = "Austrittsstelle";
                break;
                default:
                    linkPolicy = "https://www.iubenda.com/privacy-policy/7890949/full-legal";
                    message = "<b>Information</b><br>" +
                        "This website or third-party tools used by this we use cookies necessary for the operation and useful to the purposes outlined in the cookie policy. To learn more or opt out of all or some cookies, see the <a target='_blank' href='" + linkPolicy + "'>cookie policy</a>.<br>" +
                        "By accepting the information, scroll this page or continuing navigation in any other way, you consent to the use of cookies.";
                    acceptButton = "Accept";
                    cancelButton = "Exit";
                    break;

            }

            WECookieLaw.blockMessage = message;

            WECookieLaw.acceptButton = acceptButton;
            WECookieLaw.cancelButton = cancelButton;


            // verifico che sia settato il cookie WECookieLaw
            WECookieLaw.accepted(true);

        }

        // carico in memoria blockUI se non lo è già
        if(typeof jQuery.blockUI == 'undefined' && WECookieLaw.blocking ){
            jQuery.getScript(WECookieLaw.path + 'jquery.blockUI.js', run);
        }else{
            run();
        }

    },

    setWECookie: function(){
        var date = new Date();
        WECookieLaw.createCookie(WECookieLaw.cookieName, date.toGMTString(), 365);

        window.location.reload();
    },

    cancel: function(){
        window.location.href = WECookieLaw.cancelLink;
    },

    accepted: function(askModal){

        askModal = askModal || 0;

        var consenso = WECookieLaw.readCookie(WECookieLaw.cookieName) || 0;

        consenso = (consenso?true:false);

        if(!consenso){

            // se posso sbloccare sullo scroll, bindo l'evento
            if(!WECookieLaw.disableAcceptOnScrolling){
                jQuery(window).bind('scroll', function(){
                    jQuery(window).unbind('scroll');
                    WECookieLaw.setWECookie();
                });
            }

            // se non ho il consenso dell'utente ad accettare i cookie, allora blocco la UI
            if(askModal){
                if(WECookieLaw.blocking){
                    jQuery.blockUI({message: WECookieLaw.blockMessage + WECookieLaw.buttons});
                }else{
                    WECookieLaw.softBlock();
                }
            }else{
                return false;
            }
        }else{
            return true;
        }

    },

    createCookie: function (name, value, days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
        }
        else var expires = "";

        document.cookie = name + "=" + value + expires + "; path=/";
    },

    readCookie: function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },

    softBlock: function(){
        jQuery('head').append("<link rel='stylesheet' href='" + WECookieLaw.path + "wecookiepop.css'>");
        jQuery('body').append("<div class='wecookiepop-container wecookiepop-container-fixedtop wecookiepop-color-default' style='display: block;'>" +
            "<div class='wecookiepop-body'>" + WECookieLaw.blockMessage + "</div>" +
            "<div class='wecookiepop-buttons'>" +
            "<a onclick='javascript:WECookieLaw.setWECookie();return false;' href='#' class='wecookiepop-button wecookiepop-button_1'>" + (WECookieLaw.acceptButton || "Accetto") + "</a>" +
            "<a onclick='javascript:WECookieLaw.cancel();return false;' href='#' target='_blank' class='wecookiepop-button wecookiepop-button_2'>" + (WECookieLaw.cancelButton || "Esci dal sito") + "</a><div class='clearfix'></div>" +
            "</div>" +
            "<a onclick='javascript:WECookieLaw.cancel();return false;' href='#' class='wecookiepop-closebutton'>x</a></div>");
    },

    cookieName: "WECookieLaw_ACCEPTED",
    linkPolicy: "http://www.argoservice.net",
    cancelLink: '',
    blockMessage : "",
    buttons: "",
    path: "",
    acceptButton: "",
    cancelButton: "",
    blocking: false,
    disableAcceptOnScrolling: false
}

if(typeof window.jQuery == 'undefined'){
    //console.log("WECookieLaw - onReady - jQuery non trovato");
}else{
    jQuery(function(){
        //WECookieLaw.init();
    })
}