var kit = function() {

	this.bindEvents = function() {
		$("#btnKitBuy").unbind("click").bind("click", function(e) {
			e.preventDefault();

			app.formSubmit($("#frmKitBuy").attr("action"), $("#frmKitBuy"),{}, function(data){
				if(data.response) {
					if(typeof data.redirect != 'undefined')
						app.href(data.redirect);
				} else {
					app.error("", data.message);
				}
			});

		});
	};

	this.bindEvents();
};
