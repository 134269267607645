var profile = function() {

	this.bindEvents = function() {
		$("#customer_type").unbind('change').bind('change', function() {
			$('[data-customer]').hide();
			$('[data-customer="' + $(this).val() + '"]').show();
		});

		$("#customer_type").trigger('change');
	};

	this.bindEvents();
};